var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('complete-table',{attrs:{"custom-table-id":"tableManHourAttachmentB","loading":false,"data":_vm.manHourAttachments ? _vm.manHourAttachments.man_hours : []},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',{staticClass:"text-left"},[_c('th',{staticClass:"text-center",staticStyle:{"min-width":"150px"},attrs:{"rowspan":"3"}},[_vm._v(" Name ")]),_c('th',{staticClass:"text-center",staticStyle:{"min-width":"150px"},attrs:{"rowspan":"3"}},[_vm._v(" Initial ")]),_c('th',{staticClass:"text-center",staticStyle:{"min-width":"150px"},attrs:{"rowspan":"3"}},[_vm._v(" Scope ")]),_c('th',{staticClass:"text-center",staticStyle:{"min-width":"150px"},attrs:{"rowspan":"3"}},[_vm._v(" Assignment ")]),_c('th',{staticClass:"text-center",staticStyle:{"min-width":"150px"},attrs:{"rowspan":"3"}},[_vm._v(" Total Work Hour ")]),_c('th',{staticClass:"text-center",staticStyle:{"min-width":"150px"},attrs:{"colspan":_vm.manHourAttachments.stations != undefined
              ? _vm.manHourAttachments.stations.length * 2
              : 1}},[_vm._v(" Design Implementation Allocation ")])]),_c('tr',{staticClass:"text-left"},[_vm._l((_vm.manHourAttachments
            ? _vm.manHourAttachments.stations
            : []),function(station,i){return [_c('th',{key:i,staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(station.name)+" ")])]})],2),_c('tr',{staticClass:"text-left"},[_vm._l((_vm.manHourAttachments
            ? _vm.manHourAttachments.stations
            : []),function(station,i){return [_c('th',{key:i + station.name,staticClass:"text-center"},[_vm._v(" Design Number ")]),_c('th',{key:i + station.code,staticClass:"text-center"},[_vm._v(" Work Hour ")])]})],2)]},proxy:true},{key:"defaultBody",fn:function({ item, i }){return [_vm._l((item.man),function(dataMan,manIndex){return [_c('tr',{key:i + '-' + manIndex + 'man_index'},[_c('td',{staticClass:"pl-0 pt-8 text-center"},[_c('span',{staticClass:"ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(dataMan.pic ? dataMan.pic.name : "-")+" ")])]),_c('td',{staticClass:"pl-0 pt-8 text-center"},[_c('span',{staticClass:"ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(dataMan.pic ? dataMan.pic.code : "-")+" ")])]),_c('td',{staticClass:"pl-0 pt-8 text-center"},[_c('span',{staticClass:"ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(item.scope ? item.scope.name : "-")+" ")])]),_c('td',{staticClass:"pl-0 pt-8 text-center"},[_c('span',{staticClass:"ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(dataMan.hour.length > 0 ? dataMan.hour[0].assignment : "-")+" ")])]),_c('td',{staticClass:"pl-0 pt-8 text-center"},[_c('span',{staticClass:"ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(dataMan.hour.length > 0 ? dataMan.hour[0].total_hour : "-")+" ")])]),_vm._l((dataMan.hour.length > 0
              ? dataMan.hour[0].hour_station.length > 0
                ? dataMan.hour[0].hour_station
                : []
              : []),function(hourStation,hourStationIndex){return [_c('td',{key:hourStationIndex +
                  '-' +
                  manIndex +
                  '-' +
                  'hour_station_design_no',staticClass:"pl-0 pt-8 text-center"},[_c('span',{staticClass:"ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(hourStation.design_no)+" ")])]),_c('td',{key:hourStationIndex + '-' + manIndex + '-' + 'hour_station_hour',staticClass:"pl-0 pt-8 text-center"},[_c('span',{staticClass:"ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(hourStation.hour)+" ")])])]})],2),_vm._l((dataMan.hour.length > 0
            ? dataMan.hour
            : []),function(hourOnly,hourOnlyIndex){return [(hourOnlyIndex > 0)?_c('tr',{key:hourOnlyIndex + '-' + manIndex + 'man_index-next'},[_c('td',{staticClass:"pl-0 pt-8 text-center"}),_c('td',{staticClass:"pl-0 pt-8 text-center"}),_c('td',{staticClass:"pl-0 pt-8 text-center"}),_c('td',{staticClass:"pl-0 pt-8 text-center"},[_c('span',{staticClass:"ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(hourOnly.assignment)+" ")])]),_c('td',{staticClass:"pl-0 pt-8 text-center"},[_c('span',{staticClass:"ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(hourOnly.total_hour)+" ")])]),_vm._l((hourOnly.hour_station),function(hourStation,hourStationIndex){return [_c('td',{key:hourStationIndex +
                    '-' +
                    manIndex +
                    '-' +
                    'hour_station_design_no',staticClass:"pl-0 pt-8 text-center"},[_c('span',{staticClass:"ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(hourStation.design_no)+" ")])]),_c('td',{key:hourStationIndex +
                    '-' +
                    manIndex +
                    '-' +
                    'hour_station_hour',staticClass:"pl-0 pt-8 text-center"},[_c('span',{staticClass:"ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(hourStation.hour)+" ")])])]})],2):_vm._e()]})]})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
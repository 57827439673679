<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Project Code</label>

            <b-form-select
              v-model="filter.code"
              :options="formattedProjectOptions"
              size="lg"
              @change="setNickname($event)"
            ></b-form-select>
          </div>

          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Project Name</label>

            <b-form-input
              v-model="filter.nickname"
              disabled
              size="lg"
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Owner</label>

            <b-form-input
              v-model="filter.owner"
              disabled
              size="lg"
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Contract Number</label>

            <b-form-input
              v-model="filter.contract_no"
              disabled
              size="lg"
            ></b-form-input>
          </div>
        </div>

        <div class="d-flex justify-content-end mt-5" v-bind:key="i">
          <div class="d-flex ">
            <vue-excel-xlsx
              :data="getDataTable"
              :columns="getColumnTable"
              :file-name="
                filter.code +
                  '-' +
                  '' +
                  filter.nickname +
                  '' +
                  '- Grand Summary'
              "
              :sheet-name="'sheet1'"
            >
              <button
                class="btn btn-success btn-md mr-2"
                :disabled="!ableExport"
              >
                <i class="fa fa-file-excel"></i>
                Excel
              </button>
            </vue-excel-xlsx>

            <button
              class="btn btn-danger btn-md mr-2"
              @click="exportPdf"
              :disabled="!ableExport"
            >
              <i class="fa fa-file-pdf"></i>
              PDF
            </button>

            <!-- <button class="btn btn-primary btn-md mr-2" disabled>
              <i class="flaticon-upload ml-3"></i>
              Upload
            </button> -->

            <button class="btn btn-primary btn-md mr-2" @click="getReports()">
              <i class="flaticon2-search-1"></i> Show
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <b-tabs content-class="mt-3">
          <template v-for="(data, i) in tabsData">
            <b-tab @click="type = data.name" :key="i" :title="data.text">
              <div v-if="!data.isMultipleTab">
                <tab
                  :data="getDataTable"
                  :columns="data.columns"
                  :loading="loading"
                  :type="data.name"
                />
              </div>
              <span v-else>
                <v-card class="mt-5 p-3" min-height="400">
                  <b-tabs content-class="mt-5">
                    <template v-for="(data2, i2) in data.tabs">
                      <b-tab
                        @click="type = data2.name"
                        :key="i2"
                        :title="data2.text"
                      >
                        <TableManHours
                          v-if="data2.name == 'ReportManHour'"
                          :manHours="getManHourData.data1"
                        ></TableManHours>
                        <TableManHoursB
                          v-if="data2.name == 'ReportManHourLampiranB'"
                          :manHourAttachments="getManHourData.data2"
                        ></TableManHoursB>
                      </b-tab>
                    </template>
                  </b-tabs>
                </v-card>
              </span>
            </b-tab>
          </template>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import { arrayMoreThanOne } from "../../../core/helper/array-validation.helper";
import { GET_PROJECT_DETAIL } from "../../../core/services/store/project.module";
import { showToast } from "../../../core/helper/toast.helper";
import "jspdf-autotable";
import TableManHours from "./billing-and-tracking/TableManHourA";
import TableManHoursB from "./billing-and-tracking/TableManHourB";
import Tab from "./billing-and-tracking/Tab";
import {
  GET_REPORT_BILLING_CHANGE_MANAGEMENT,
  GET_REPORT_BILLING_DESIGN,
  GET_REPORT_BILLING_DISTRIBUTION,
  GET_REPORT_BILLING_LETTER_IN_OUT,
  GET_REPORT_BILLING_MINUTE_OF_MEETING,
  GET_REPORT_BILLING_MAN_HOUR,
  GET_REPORT_BILLING_MAN_HOUR_LAMPIRAN_B,
  GET_REPORT_BILLING_BOM_DESIGN,
  GET_REPORT_BILLING_SHOP_DRAWING_INTERNAL,
  GET_REPORT_BILLING_SHOP_DRAWING_EXTERNAL,
  GET_REPORT_BILLING_ASBUILT_DRAWING,
  GET_REPORT_BILLING_KELENGKAPAN_DOKUMEN_PROYEK,
  GET_REPORT_BILLING_TRANSMITTAL,
  GET_REPORT_BILLING_TRACKING_DESIGN_BILLING,
} from "../../../core/services/store/report-billing-tracking.module";
import moment from "moment";
import jsPDF from "jspdf";
import { logos } from "@/assets/js/components/logos64bit.js";

export default {
  components: {
    Tab,
    TableManHours,
    TableManHoursB,
  },
  data() {
    return {
      filter: {
        code: null,
        nickname: null,
        scope: null,
        revision_version: null,
        owner: null,
        contract_no: null,
        jobdesk: [
          "interface",
          "design",
          "planner",
          "drafter",
          "koor",
          "enginer",
          "manager",
          "admin",
        ],
      },
      type: "BillingAndTracking",
      tabsData: [
        {
          name: "BillingAndTracking",
          text: "Billing & Tracking Design",
          isMultipleTab: false,
          columns: [
            {
              label: "Numbering",
              field: "numbering",
              withWrapper: true,
              style: "min-width: 250px",
            },
            {
              label: "Title",
              field: "title",
              withWrapper: false,
              style: "min-width: 250px",
            },
            {
              label: "Scope",
              field: "scope",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Station",
              field: "stationCode",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Type",
              field: "type",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Date",
              field: "date_receive",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Status",
              field: "last_status",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Revision",
              field: "revision_version",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Version",
              field: "version",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Plan Date",
              field: "planning_date",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Delay",
              field: "delay",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Logistic",
              field: "monitoring_logistic_status",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "SHD External",
              field: "monitoring_shd_external_status",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Asbuilt",
              field: "monitoring_asbuilt_status",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Change Management",
              field: "monitoring_change_management_status",
              withWrapper: false,
              style: "min-width: 150px",
            },
          ],
          tabs: [],
        },
        {
          name: "Distribusi",
          text: "Distribusi",
          isMultipleTab: false,
          columns: [
            {
              label: "Numbering",
              field: "numbering",
              withWrapper: true,
              style: "min-width: 250px",
            },
            {
              label: "Title",
              field: "title",
              withWrapper: false,
              style: "min-width: 250px",
            },
            {
              label: "Station",
              field: "stasiun",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Last Status",
              field: "last_status",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Last Revision",
              field: "last_revision",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Date",
              field: "date",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Last Date",
              field: "last_date",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Drawing",
              field: "drawing",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Transmittal",
              field: "transmittal",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "In Floor",
              field: "in_floor",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Needs",
              field: "needs",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Internal",
              field: "monitoring_logistic_status",
              type: "checkbox",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Shd External",
              field: "monitoring_shd_external_status",
              withWrapper: false,
              type: "checkbox",
              style: "min-width: 150px",
            },
          ],
          tabs: [],
        },
        {
          name: "ChangeManagement",
          text: "Change Management",
          isMultipleTab: false,
          columns: [
            {
              label: "Numbering",
              field: "cr_number",
              withWrapper: true,
              style: "min-width: 150px",
            },
            {
              label: "Title",
              field: "title",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Project",
              field: "project",
              valuechild: "name",
              withWrapper: false,
              style: "min-width: 250px",
            },
            {
              label: "Type",
              field: "type",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Action",
              field: "item",
              type: "buttonChangeMgt",
              withWrapper: false,
              style: "min-width: 150px",
            },
          ],
          tabs: [],
        },
        {
          name: "MinuteOfMeeting",
          text: "MoM",
          isMultipleTab: false,
          columns: [
            {
              label: "MoM Number",
              field: "mom_number",
              withWrapper: true,
              style: "min-width: 250px",
            },
            // {
            //   label: "Attendance",
            //   field: "peserta",
            //   withWrapper: false,
            //   style: "min-width: 150px"
            // },
            {
              label: "Tanggal",
              field: "tanggal",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Place",
              field: "tempat",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Meeting Subject",
              field: "subject_rapat",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Soft File",
              field: "soft_file",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Hard File",
              field: "hard_file",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Status",
              field: "status",
              withWrapper: false,
              style: "min-width: 150px",
            },
          ],
          tabs: [],
        },
        {
          name: "LetterInOut",
          text: "Letter in Out",
          isMultipleTab: false,
          columns: [
            {
              label: "Type",
              field: "type",
              withWrapper: true,
              style: "min-width: 150px",
            },
            {
              label: "Date",
              field: "tanggal",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Letter Number",
              field: "nomort_surat",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "Subject",
              field: "subject",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "PIC",
              field: "pic",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "From",
              field: "dari",
              withWrapper: false,
              style: "min-width: 150px",
            },
            {
              label: "To",
              field: "dikirim_ke",
              withWrapper: false,
              style: "min-width: 150px",
            },
          ],
          tabs: [],
        },
        {
          name: "ManHour",
          text: "Man Hour",
          isMultipleTab: true,
          isCustom: true,
          tabs: [
            {
              name: "ReportManHour",
              text: "Report Man Hour",
            },
            {
              name: "ReportManHourLampiranB",
              text: "Lampiran B",
            },
          ],
        },
        {
          name: "BomDesign",
          text: "BOM Design",
          isMultipleTab: false,
          columns: [
            {
              label: "No",
              field: "no",
              withWrapper: false,
              style: "min-width: 60px",
            },
            {
              label: "Numbering",
              field: "numbering",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Title",
              field: "title",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Station",
              field: "station",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Scope",
              field: "scope",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Type",
              field: "type",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Date",
              field: "plan_date",
              withWrapper: false,
              style: "min-width: 80px",
            },
            {
              label: "Status",
              field: "last_status_code",
              withWrapper: false,
              style: "min-width: 80px",
            },
            {
              label: "Revision",
              field: "revision_version",
              withWrapper: false,
              style: "min-width: 80px",
            },
            {
              label: "Version",
              field: "version",
              withWrapper: false,
              style: "min-width: 80px",
            },
            {
              label: "Outstanding",
              field: "outstanding",
              withWrapper: false,
              style: "min-width: 80px",
            },
          ],
          tabs: [],
        },
        {
          name: "ShopDrawingInternal",
          text: "Design Internal",
          isMultipleTab: false,
          columns: [
            {
              label: "No",
              field: "no",
              withWrapper: false,
              style: "min-width: 60px",
            },
            {
              label: "Numbering",
              field: "numbering",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Title",
              field: "title",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Station",
              field: "stasiun",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Scope",
              field: "scope",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Type",
              field: "type",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Received Date",
              field: "date_receive",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Status",
              field: "last_status_code",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Revision",
              field: "revision_version",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Version",
              field: "version",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Receiver",
              field: "logistic_receiver",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Distribution Date",
              field: "date_dist_logistic",
              withWrapper: false,
              style: "min-width: 100px",
            },
          ],
          tabs: [],
        },
        {
          name: "ShopDrawingEksternal",
          text: "Design Eksternal",
          isMultipleTab: false,
          columns: [
            {
              label: "No",
              field: "no",
              withWrapper: false,
              style: "min-width: 60px",
            },
            {
              label: "Numbering",
              field: "numbering",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Title",
              field: "title",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Station",
              field: "stasiun",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Tipe",
              field: "type",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Scope",
              field: "scope",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Status",
              field: "status",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Revision",
              field: "revisi",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Deadline",
              field: "deadline",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "PIC Drafter",
              field: "drafter_pic",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Received Date",
              field: "date_receive",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Receiver",
              field: "eksternal_receiver",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Transmittal Number",
              field: "receiver",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Keterangan",
              field: "keterangan",
              withWrapper: false,
              style: "min-width: 100px",
            },
          ],
          tabs: [],
        },
        {
          name: "AsbuiltDrawing",
          text: "Asbuilt Drawing",
          isMultipleTab: false,
          columns: [
            {
              label: "No",
              field: "no",
              withWrapper: false,
              style: "min-width: 60px",
            },
            {
              label: "Numbering",
              field: "numbering",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Extenal Number",
              field: "external_numbering",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Asbuilt Drawing Number",
              field: "asbuilt_drawing_numbering",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Title",
              field: "title",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Station",
              field: "stasiun",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Scope",
              field: "scope",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Type",
              field: "type",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Last Status",
              field: "last_status_code",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Revision",
              field: "revision_version",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Version",
              field: "version",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Received",
              field: "receiver",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Distribution Date",
              field: "received_date",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Date Receive",
              field: "date_receive",
              withWrapper: false,
              style: "min-width: 100px",
            },
          ],
          tabs: [],
        },
        {
          name: "KelengkapanDokumenProyek",
          text: "Kelengkapan Dokumen Proyek",
          isMultipleTab: false,
          columns: [
            {
              label: "No",
              field: "no",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Category",
              field: "category",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Item",
              field: "item",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Complete",
              field: "complete",
              type: "checkbox",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Description",
              field: "description",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "note",
              field: "note",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "date",
              field: "date",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Storage",
              field: "storage",
              withWrapper: false,
              style: "min-width: 100px",
            },
          ],
          tabs: [],
        },
        {
          name: "Transmittal",
          text: "Transmittal",
          isMultipleTab: false,
          columns: [
            {
              label: "Company",
              field: "company_code",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Subject",
              field: "subject",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Project Code",
              field: "project_code",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Nickname",
              field: "project",
              valuechild: "nickname",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Transmittal Date",
              field: "date",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Transmittal Number",
              field: "number",
              withWrapper: false,
              style: "min-width: 100px",
            },
            {
              label: "Action",
              field: "item",
              type: "buttonTransmittal",
              withWrapper: false,
              style: "min-width: 100px",
            },
          ],
          tabs: [],
        },
      ],
    };
  },
  computed: {
    ...mapState({
      billingAndTracking: (state) =>
        state.reportBillingTracking.billingAndTrackingReport,
      projects: (state) => state.projectDetails.projectDetails?.data,
      designs: (state) => state.reportBillingTracking.designs,
      distributions: (state) => state.reportBillingTracking.distributions,
      changeManagements: (state) =>
        state.reportBillingTracking.changeManagements,
      minuteOfMeetings: (state) => state.reportBillingTracking.minuteOfMeetings,
      letterInOuts: (state) => state.reportBillingTracking.letterInOuts,
      manHour: (state) => state.reportBillingTracking.manHour,
      manHourLampiranB: (state) => state.reportBillingTracking.manHourLampiranB,
      bomDesign: (state) => state.reportBillingTracking.bomDesign,
      shopDrawingIntenal: (state) =>
        state.reportBillingTracking.shopDrawingInternal,
      shopDrawingExternal: (state) =>
        state.reportBillingTracking.shopDrawingExternal,
      asbuiltDrawing: (state) => state.reportBillingTracking.asbuiltDrawing,
      kelengkapanDokumenProyek: (state) =>
        state.reportBillingTracking.kelengkapanDokumenProyek,
      transmittal: (state) => state.reportBillingTracking.transmittal,
      loading: (state) => state.reportBillingTracking.loading,
    }),

    formattedProjectOptions() {
      let projects = [];

      if (arrayMoreThanOne(this.projects)) {
        projects = this.projects.map((project) => {
          return {
            value: project.code,
            text: project.code + "-" + project.nickname,
          };
        });
      }

      return [{ value: null, text: "Choose Project" }, ...projects];
    },

    currentAsbuiltData() {
      return this.asBuiltDrawings;
    },

    formattedScopeOptions() {
      let scopes = [];

      if (arrayMoreThanOne(this.scopes)) {
        scopes = this.scopes.map((scope) => {
          return {
            value: scope.code,
            text: scope.name,
          };
        });
      }

      return [{ value: null, text: "Choose Scope" }, ...scopes];
    },
    getManHourData() {
      return {
        data1: this.manHour,
        data2: this.manHourLampiranB,
      };
    },

    ableExport() {
      return (
        this.billingAndTracking?.length > 0 ||
        this.manHour.length > 0 ||
        this.designs.length > 0 ||
        this.distributions.length > 0 ||
        this.changeManagements.length > 0 ||
        this.minuteOfMeetings.length > 0 ||
        this.letterInOuts.length > 0 ||
        this.bomDesign.length > 0 ||
        this.shopDrawingExternal.length > 0 ||
        this.shopDrawingIntenal.length > 0 ||
        this.asbuiltDrawing.length > 0 ||
        this.kelengkapanDokumenProyek > 0 ||
        this.transmittal > 0
      );
    },

    getColumnTable() {
      var column;
      if (this.filter.code) {
        switch (this.type) {
          case "BillingAndTracking":
            column = this.tabsData[0].columns;
            break;
          case "Design":
            column = this.tabsData[0].columns;
            break;
          case "Distribusi":
            column = this.tabsData[1].columns;
            break;
          case "ChangeManagement":
            column = this.tabsData[2].columns;
            break;
          case "MinuteOfMeeting":
            column = this.tabsData[3].columns;
            break;
          case "LetterInOut":
            column = this.tabsData[4].columns;
            break;
          case "BomDesign":
            column = this.tabsData[6].columns;
            break;
          case "ShopDrawingInternal":
            column = this.tabsData[7].columns;
            break;
          case "ShopDrawingEksternal":
            column = this.tabsData[8].columns;
            break;
          case "AsbuiltDrawing":
            column = this.tabsData[9].columns;
            break;
          case "KelengkapanDokumenProyek":
            column = this.tabsData[10].columns;
            break;
          case "Transmittal":
            column = this.tabsData[11].columns;
            break;
        }
      }
      return column;
    },

    getDataTable() {
      var data;
      if (this.filter.code) {
        switch (this.type) {
          case "BillingAndTracking":
            data = this.billingAndTracking;
            break;
          case "ManHour":
            data = {
              data1: this.manHour,
              data2: this.manHourLampiranB,
            };
            break;
          case "Design":
            data = this.designs;
            break;
          case "Distribusi":
            data = this.distributions;
            break;
          case "ChangeManagement":
            data = this.changeManagements;
            break;
          case "MinuteOfMeeting":
            data = this.minuteOfMeetings;
            break;
          case "LetterInOut":
            data = this.letterInOuts;
            break;
          case "BomDesign":
            data = this.bomDesign;
            break;
          case "ShopDrawingInternal":
            data = this.shopDrawingIntenal;
            break;
          case "ShopDrawingEksternal":
            data = this.shopDrawingExternal;
            break;
          case "AsbuiltDrawing":
            data = this.asbuiltDrawing;
            break;
          case "KelengkapanDokumenProyek":
            data = this.kelengkapanDokumenProyek;
            break;
          case "Transmittal":
            data = this.transmittal;
            break;
        }
      }
      return data;
    },

    getDataTablePdf() {
      var data;
      if (this.filter.code) {
        switch (this.type) {
          case "BillingAndTracking":
            data = this.billingAndTracking?.map((item) => {
              return [
                item.numbering,
                item.title,
                item.scope,
                item.station,
                item.type,
                item.date_receive
                  ? moment(item.date_receive).format("DD-MM-YYYY")
                  : "-",
                item.last_status,
                item.revision_version,
                item.version,
                item.planning_date
                  ? moment(item.planning_date).format("DD-MM-YYYY")
                  : "-",
                item.delay,
                item.date_dist_logistic
                  ? moment(item.date_dist_logistic).format("DD-MM-YYYY")
                  : "-",
                item.eksternal_distribution_date
                  ? moment(item.eksternal_distribution_date).format(
                      "DD-MM-YYYY"
                    )
                  : "-",
                item.asbuilt_distribution_date
                  ? moment(item.asbuilt_distribution_date).format("DD-MM-YYYY")
                  : "-",
                item.change_management_desgin,
              ];
            });
            break;
          case "ManHour":
            data = {
              data1: this.manHour,
              data2: this.manHourLampiranB,
            };
            break;
          case "Design":
            data = this.designs;
            break;
          case "Distribusi":
            data = this.distributions?.map((item) => {
              return [
                item.numbering,
                item.title,
                item.station?.name,
                item.last_status,
                item.revision_version,
                moment(item.date).format("DD-MM-YYYY"),
                moment(item.last_date).format("DD-MM-YYYY"),
                item.drawing,
                item.transmittal,
                item.in_floor,
                item.needs,
                item.monitoring_logistic_status,
                item.monitoring_shd_external_status,
              ];
            });
            break;
          case "ChangeManagement":
            data = this.changeManagements?.map((item) => {
              return [
                item.cr_number,
                item.title,
                item.project?.name,
                item.type,
              ];
            });
            break;
          case "MinuteOfMeeting":
            data = this.minuteOfMeetings?.map((item) => {
              return [
                item.code,
                item.date,
                item.place,
                item.subject,
                item.soft_file,
                item.hard_file,
                item.status,
              ];
            });
            break;
          case "LetterInOut":
            data = this.letterInOuts?.map((item) => {
              return [
                item.type,
                moment(item.date).format("DD-MM-YYYY"),
                item.nomort_surat,
                item.subject,
                item.pic,
                item.dari,
                item.dikirim_ke,
              ];
            });
            break;
          case "BomDesign":
            data = this.bomDesign?.map((item, i) => {
              return [
                i + 1,
                item.numbering,
                item.title,
                item.station?.name,
                item.scope,
                item.type,
                moment(item.date).format("DD-MM-YYYY"),
                item.last_status_code,
                item.revision_version,
                item.version,
                item.outstanding,
              ];
            });
            break;
          case "ShopDrawingInternal":
            data = this.shopDrawingIntenal?.map((item, i) => {
              return [
                i + 1,
                item.numbering,
                item.title,
                item.station?.name,
                item.scope,
                item.type,
                item.date_receive
                  ? moment(item.date_receive).format("DD-MM-YYYY")
                  : "-",
                item.last_status_code,
                item.revision_version,
                item.version,
                item.logistic_receiver,
                item.date_dist_logistic
                  ? moment(item.date_dist_logistic).format("DD-MM-YYYY")
                  : "-",
              ];
            });
            break;
          case "ShopDrawingEksternal":
            data = this.shopDrawingExternal?.map((item, i) => {
              return [
                i + 1,
                item.numbering,
                item.title,
                item.station?.name,
                item.type,
                item.scope,
                item.last_status_code,
                item.revision_version,
                moment(item.deadline).format("DD-MM-YYYY"),
                item.drafter_pic,
                item.date_receive
                  ? moment(item.date_receive).format("DD-MM-YYYY")
                  : "-",
                item.eksternal_receiver,
                item.transmittal_number,
                item.keterangan,
              ];
            });
            break;
          case "AsbuiltDrawing":
            data = this.asbuiltDrawing?.map((item, i) => {
              return [
                i + 1,
                item.numbering,
                item.external_numbering,
                item.asbuilt_drawing_numbering,
                item.title,
                item.station?.name,
                item.scope,
                item.type,
                item.last_status_code,
                item.revision_version,
                item.version,
                item.receiver,
                moment(item.date_dist_logistic).format("DD-MM-YYYY"),
                item.date_receive
                  ? moment(item.date_receive).format("DD-MM-YYYY")
                  : "-",
              ];
            });
            break;
          case "KelengkapanDokumenProyek":
            data = this.kelengkapanDokumenProyek?.map((item, i) => {
              return [
                i + 1,
                item.category,
                item.item,
                item.complete,
                item.description,
                item.note,
                moment(item.date).format("DD-MM-YYYY"),
                item.storage ? "-" : item.storage,
              ];
            });
            break;
          case "Transmittal":
            data = this.transmittal?.map((item) => {
              return [
                item.company_code,
                item.subject,
                item.project_code,
                item.project.nickname,
                moment(item.date).format("DD-MM-YYYY"),
                item.number,
                item.stamp_signature,
              ];
            });
            break;
        }
      }
      return data;
    },
  },
  methods: {
    getProjects() {
      this.$store.dispatch(GET_PROJECT_DETAIL, {
        jobdesk: "design",
        params: this.filter.jobdesk,
      });
    },

    getReports() {
      if (this.filter.code) {
        switch (this.type) {
          case "BillingAndTracking":
            this.fetchData(GET_REPORT_BILLING_TRACKING_DESIGN_BILLING);
            break;
          case "ManHour":
            this.fetchData(GET_REPORT_BILLING_MAN_HOUR);
            this.fetchData(GET_REPORT_BILLING_MAN_HOUR_LAMPIRAN_B);
            break;
          case "Design":
            this.fetchData(GET_REPORT_BILLING_DESIGN);
            break;
          case "Distribusi":
            this.fetchData(GET_REPORT_BILLING_DISTRIBUTION);
            break;
          case "ChangeManagement":
            this.fetchData2(GET_REPORT_BILLING_CHANGE_MANAGEMENT);
            break;
          case "MinuteOfMeeting":
            this.fetchData(GET_REPORT_BILLING_MINUTE_OF_MEETING);
            break;
          case "LetterInOut":
            this.fetchData(GET_REPORT_BILLING_LETTER_IN_OUT);
            break;
          case "BomDesign":
            this.fetchData(GET_REPORT_BILLING_BOM_DESIGN);
            break;
          case "ShopDrawingInternal":
            this.fetchData(GET_REPORT_BILLING_SHOP_DRAWING_INTERNAL);
            break;
          case "ShopDrawingEksternal":
            this.fetchData(GET_REPORT_BILLING_SHOP_DRAWING_EXTERNAL);
            break;
          case "AsbuiltDrawing":
            this.fetchData(GET_REPORT_BILLING_ASBUILT_DRAWING);
            break;
          case "KelengkapanDokumenProyek":
            this.fetchData(GET_REPORT_BILLING_KELENGKAPAN_DOKUMEN_PROYEK);
            break;
          case "Transmittal":
            this.fetchData(GET_REPORT_BILLING_TRANSMITTAL);
            break;
        }
      } else {
        showToast("Failed", "Please Choose Project First", "danger");
      }
    },

    fetchData(topic) {
      this.$store.dispatch(topic, {
        code: this.filter.code,
      });
    },

    fetchData2(topic) {
      this.$store.dispatch(topic, {
        code_project: this.filter.code,
      });
    },

    exportPdf() {
      moment.locale("id");
      var totalPagesExp = "{total_pages_count_string}";
      var doc = new jsPDF("landscape");
      const today = new Date();
      var projectCode = this.filter.code !== null ? this.filter.code : "-";

      var nickname = this.filter.nickname !== null ? this.filter.nickname : "-";

      var logo = logos;

      doc.setFontSize(11);

      const headRows = [];
      this.getColumnTable.map((x) => {
        headRows.push(x.label);
      });
      var body = [];
      this.getDataTablePdf.map((c) => {
        console.log("itu", c);
        const propertyValues = Object.values(c);
        body.push(propertyValues);
      });

      doc.autoTable({
        headStyles: {
          halign: "center",
          valign: "middle",
        },
        styles: {
          fontSize: 8,
        },
        head: [headRows],
        body: body,
        columnStyles: {
          0: { columnWidth: 22 },
          // 1: { columnWidth: 30 },
          // 2: { columnWidth: 20 },
          // 3: { columnWidth: 15 },
          // 4: { columnWidth: 15 },
          5: { columnWidth: 20 },
          6: { columnWidth: 15 },
          7: { columnWidth: 15 },
          8: { columnWidth: 15 },
          9: { columnWidth: 15 },
          10: { columnWidth: 15 },
          11: { columnWidth: 15 },
          12: { columnWidth: 15 },
          13: { columnWidth: 15 },
          14: { columnWidth: 15 },
        },
        didDrawPage: function(data) {
          // Header
          doc.setFontSize(20);
          doc.setTextColor(40);
          doc.addImage(logo, "png", data.settings.margin.left, 10, 25, 15);
          doc.text(
            "Reporting Grand Summary",
            data.settings.margin.left + 80,
            25
          );
          doc.setFontSize(8);

          doc.text(
            `Day/Date : ${moment(today).format("dddd")}/ ${moment(today).format(
              "ll"
            )}`,
            data.settings.margin.left + 200,
            15
          );
          doc.text(
            `Time :  ${moment(today).format("LTS")}`,
            data.settings.margin.left + 200,
            20
          );

          doc.text(
            `Project Code: ${projectCode}`,
            data.settings.margin.left + 200,
            25
          );

          doc.text(
            `Project Name: ${nickname}`,
            data.settings.margin.left + 200,
            30
          );

          /*  var lines = doc.splitTextToSize(`Project name: ${projectName}`, (pdfInMM-lMargin-rMargin));
          doc.text(
            lines,
            data.settings.margin.left,
           45
          ); */

          // Footer
          var str = "Page " + doc.internal.getNumberOfPages();
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === "function") {
            str = str + " of " + totalPagesExp;
          }
          doc.setFontSize(10);

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          var footer_lrs = 'PT. LEN RAILWAY SYSTEMS';
          doc.text(str, 260, pageHeight - 10);
          doc.text(footer_lrs, data.settings.margin.left, pageHeight - 10);
        },
        margin: { top: 45 },
      });

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPagesExp);
      }
      var grandSummary =
        this.filter.code + "-" + this.filter.nickname + "- Grand Summary.pdf";
      doc.save(grandSummary);
    },

    setNickname(value) {
      const project = this.projects?.find((project) => project?.code === value);

      this.filter.nickname = project?.nickname;

      this.filter.revision_version = project?.dmp_rev;

      this.filter.owner = project?.owner;

      this.filter.contract_no = project?.contract_no;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Reporting Grand Summary" },
    ]);

    this.getProjects();
  },
};
</script>

<template>
  <div>
    <complete-table :data="data" :loading="loading">
      <template #header>
        <tr class="border-0">
          <template v-for="(column, columnIndex) in columns">
            <th
              :style="column.style"
              class="pl-0 pt-8"
              :key="columnIndex + type + 'header'"
            >
              <span
                v-if="column.withWrapper"
                class="ml-5 text-dark-75 font-weight-bseolder d-block font-size-lg"
              >
                {{ column.label }}
              </span>

              <template v-else>
                {{ column.label }}
              </template>
            </th>
          </template>
        </tr>
      </template>

      <template #defaultBody="{ item, i }">
        <tr v-bind:key="i" class="border-0">
          <template v-for="(column, columnIndex) in columns">
            <td class="pl-0 pt-8" :key="i + columnIndex + type + 'body'">
              <span
                v-if="column.withWrapper"
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item[column.field] }}
              </span>

              <span
                v-else
                class="text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                <b-form-checkbox
                  v-if="column.type == 'checkbox'"
                  disabled
                  class="custom-control-inline"
                  v-model="item[column.field]"
                  field="1"
                />
                <a
                  v-if="
                    column.field == 'date_receive' ||
                      column.field == 'date' ||
                      column.field == 'planning_date' ||
                      column.field == 'last_date' ||
                      column.field == 'tanggal' ||
                      column.field == 'plan_date' ||
                      column.field == 'date_dist_logistic' ||
                      column.field == 'received_date' ||
                      column.field == 'deadline'
                  "
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ moment(item[column.field]).format("DD-MM-YYYY") }}
                </a>

                <a
                  v-else-if="column.type == 'buttonTransmittal'"
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="toTransmittalList(item.item)"
                  title="List Transmittal"
                >
                  <i class="flaticon-book"></i>
                </a>
                <a
                  v-else-if="column.type == 'buttonChangeMgt'"
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="toChangeManagement(item)"
                  title="List Change Management"
                >
                  <i class="flaticon-eye"></i>
                </a>
                <span v-else>
                  {{
                    column.valuechild !== undefined &&
                    item[column.field] !== undefined
                      ? item[column.field][column.valuechild]
                      : item[column.field]
                  }}
                </span>
              </span>
            </td>
          </template>
        </tr>
      </template>
    </complete-table>
  </div>
</template>

<script>
import CompleteTable from "../../../content/widgets/advance-table/CompleteTable";
export default {
  name: "tab",
  components: {
    CompleteTable,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      required: true,
    },
    contentType: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      //
    };
  },
  methods: {
    toTransmittalList(data) {
      this.$router.push({
        name: "transmittal-list",
        params: {
          id: data.code,
        },
      });
    },
    toChangeManagement(data) {
      this.$router.push({
        name: "change/management",
        params: {
          id: data.id,
        },
      });
    },
  },
  mounted() {
    //
  },
  computed: {
    //
  },
  watch: {
    //
  },
};
</script>

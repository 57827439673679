<template>
  <div>
    <complete-table
      :data="manHours"
      :loading="false"
      custom-table-id="manHourTable"
    >
      <template #header>
        <tr class="text-left">
          <th style="min-width: 200px" class="pl-7" rowspan="3">
            <span class="text-dark-75">Design Number</span>
          </th>
          <th style="min-width: 250px" rowspan="3">Numbering</th>
          <th style="min-width: 250px" rowspan="3">Title</th>
          <th style="min-width: 250px" rowspan="3">Station</th>
          <th style="min-width: 150px" rowspan="3">Scope</th>
          <th style="min-width: 150px" rowspan="3">Category</th>
          <th style="min-width: 150px" rowspan="3">Plan Date</th>
          <th style="min-width: 150px" rowspan="3">Type</th>
          <th style="min-width: 150px" colspan="6" class="text-center">
            Man Hour Allocation
          </th>
          <th style="min-width: 150px" rowspan="3">Total Man Hour</th>
        </tr>

        <tr class="text-left">
          <th style="min-width: 150px" colspan="2" class="text-center">
            Design
          </th>
          <th style="min-width: 150px" colspan="2" class="text-center">
            Drafter
          </th>
          <th style="min-width: 150px" colspan="2" class="text-center">
            Checker
          </th>
        </tr>

        <tr class="text-left">
          <th style="min-width: 150px" class="text-center">PIC</th>
          <th style="min-width: 150px" class="text-center">Man Hour</th>
          <th style="min-width: 150px" class="text-center">PIC</th>
          <th style="min-width: 150px" class="text-center">Man Hour</th>
          <th style="min-width: 150px" class="text-center">PIC</th>
          <th style="min-width: 150px" class="text-center">Man Hour</th>
        </tr>
      </template>

      <template #defaultBody="{item: manHour, i}">
        <tr v-bind:key="i" class="border-0">
          <td class="pl-0 pt-8">
            <span
              class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              {{ manHour.no }}
            </span>
          </td>

          <td class="pl-0 pt-8">
            <span
              class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              {{ manHour.numbering }}
            </span>
          </td>

          <td class="pl-0 pt-8">
            <span
              class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              {{ manHour.title }}
            </span>
          </td>

          <td class="pl-0 pt-8">
            <span
              class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              {{ manHour.station ? manHour.station.name : "-" }}
            </span>
          </td>

          <td class="pl-0 pt-8">
            <span
              class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              {{ manHour.scope ? manHour.scope.name : "-" }}
            </span>
          </td>

          <td class="pl-0 pt-8">
            <span
              class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              {{ manHour.category ? manHour.category.name : "-" }}
            </span>
          </td>

          <td class="pl-0 pt-8">
            <span
              class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              {{
                manHour.plan_date
                  ? moment(manHour.plan_date).format("DD-MM-YYYY")
                  : "-"
              }}
            </span>
          </td>

          <td class="pl-0 pt-8">
            <span
              class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              {{ manHour.type ? manHour.type.name : "-" }}
            </span>
          </td>

          <td class="pl-0 pt-8 text-center">
            <span
              class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              {{ manHour.designer_pic ? manHour.designer_pic.name : "-" }}
            </span>
          </td>

          <td class="pl-0 pt-8 text-center">
            <span
              class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              {{ manHour.designer_hour }}
            </span>
          </td>

          <td class="pl-0 pt-8 text-center">
            <span
              class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              {{ manHour.drafter_pic ? manHour.drafter_pic.name : "-" }}
            </span>
          </td>

          <td class="pl-0 pt-8 text-center">
            <span
              class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              {{ manHour.drafter_hour }}
            </span>
          </td>

          <td class="pl-0 pt-8 text-center">
            <span
              class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              {{ manHour.checker_pic ? manHour.checker_pic.name : "-" }}
            </span>
          </td>

          <td class="pl-0 pt-8 text-center">
            <span
              class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              {{ manHour.checker_hour }}
            </span>
          </td>

          <td class="pl-0 pt-8 text-center">
            <span
              class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              {{ manHour.total_man_hour }}
            </span>
          </td>
        </tr>
      </template>
    </complete-table>
  </div>
</template>

<script>
import CompleteTable from "../../../content/widgets/advance-table/CompleteTable";
export default {
  name: "TableManHours",
  components: { CompleteTable },
  props: ["manHours"],
};
</script>

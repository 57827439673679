var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('complete-table',{attrs:{"data":_vm.data,"loading":_vm.loading},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',{staticClass:"border-0"},[_vm._l((_vm.columns),function(column,columnIndex){return [_c('th',{key:columnIndex + _vm.type + 'header',staticClass:"pl-0 pt-8",style:(column.style)},[(column.withWrapper)?_c('span',{staticClass:"ml-5 text-dark-75 font-weight-bseolder d-block font-size-lg"},[_vm._v(" "+_vm._s(column.label)+" ")]):[_vm._v(" "+_vm._s(column.label)+" ")]],2)]})],2)]},proxy:true},{key:"defaultBody",fn:function({ item, i }){return [_c('tr',{key:i,staticClass:"border-0"},[_vm._l((_vm.columns),function(column,columnIndex){return [_c('td',{key:i + columnIndex + _vm.type + 'body',staticClass:"pl-0 pt-8"},[(column.withWrapper)?_c('span',{staticClass:"ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(item[column.field])+" ")]):_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg"},[(column.type == 'checkbox')?_c('b-form-checkbox',{staticClass:"custom-control-inline",attrs:{"disabled":"","field":"1"},model:{value:(item[column.field]),callback:function ($$v) {_vm.$set(item, column.field, $$v)},expression:"item[column.field]"}}):_vm._e(),(
                  column.field == 'date_receive' ||
                    column.field == 'date' ||
                    column.field == 'planning_date' ||
                    column.field == 'last_date' ||
                    column.field == 'tanggal' ||
                    column.field == 'plan_date' ||
                    column.field == 'date_dist_logistic' ||
                    column.field == 'received_date' ||
                    column.field == 'deadline'
                )?_c('a',{staticClass:"ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"},[_vm._v(" "+_vm._s(_vm.moment(item[column.field]).format("DD-MM-YYYY"))+" ")]):(column.type == 'buttonTransmittal')?_c('a',{staticClass:"btn btn-light-success font-weight-bolder font-size-sm",attrs:{"title":"List Transmittal"},on:{"click":function($event){return _vm.toTransmittalList(item.item)}}},[_c('i',{staticClass:"flaticon-book"})]):(column.type == 'buttonChangeMgt')?_c('a',{staticClass:"btn btn-light-success font-weight-bolder font-size-sm",attrs:{"title":"List Change Management"},on:{"click":function($event){return _vm.toChangeManagement(item)}}},[_c('i',{staticClass:"flaticon-eye"})]):_c('span',[_vm._v(" "+_vm._s(column.valuechild !== undefined && item[column.field] !== undefined ? item[column.field][column.valuechild] : item[column.field])+" ")])],1)])]})],2)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <complete-table
      custom-table-id="tableManHourAttachmentB"
      :loading="false"
      :data="manHourAttachments ? manHourAttachments.man_hours : []"
    >
      <template #header>
        <tr class="text-left">
          <th class="text-center" style="min-width: 150px" rowspan="3">
            Name
          </th>
          <th class="text-center" style="min-width: 150px" rowspan="3">
            Initial
          </th>
          <th class="text-center" style="min-width: 150px" rowspan="3">
            Scope
          </th>
          <th class="text-center" style="min-width: 150px" rowspan="3">
            Assignment
          </th>
          <th class="text-center" style="min-width: 150px" rowspan="3">
            Total Work Hour
          </th>
          <th
            style="min-width: 150px"
            class="text-center"
            :colspan="
              manHourAttachments.stations != undefined
                ? manHourAttachments.stations.length * 2
                : 1
            "
          >
            Design Implementation Allocation
          </th>
        </tr>

        <tr class="text-left">
          <template
            v-for="(station, i) in manHourAttachments
              ? manHourAttachments.stations
              : []"
          >
            <th class="text-center" :key="i" colspan="2">
              {{ station.name }}
            </th>
          </template>
        </tr>

        <tr class="text-left">
          <template
            v-for="(station, i) in manHourAttachments
              ? manHourAttachments.stations
              : []"
          >
            <th class="text-center" :key="i + station.name">
              Design Number
            </th>

            <th class="text-center" :key="i + station.code">
              Work Hour
            </th>
          </template>
        </tr>
      </template>

      <template #defaultBody="{ item, i }">
        <template v-for="(dataMan, manIndex) in item.man">
          <tr :key="i + '-' + manIndex + 'man_index'">
            <td class="pl-0 pt-8 text-center">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ dataMan.pic ? dataMan.pic.name : "-" }}
              </span>
            </td>

            <td class="pl-0 pt-8 text-center">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ dataMan.pic ? dataMan.pic.code : "-" }}
              </span>
            </td>

            <td class="pl-0 pt-8 text-center">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.scope ? item.scope.name : "-" }}
              </span>
            </td>

            <td class="pl-0 pt-8 text-center">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ dataMan.hour.length > 0 ? dataMan.hour[0].assignment : "-" }}
              </span>
            </td>

            <td class="pl-0 pt-8 text-center">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ dataMan.hour.length > 0 ? dataMan.hour[0].total_hour : "-" }}
              </span>
            </td>

            <template
              v-for="(hourStation, hourStationIndex) in dataMan.hour.length > 0
                ? dataMan.hour[0].hour_station.length > 0
                  ? dataMan.hour[0].hour_station
                  : []
                : []"
            >
              <td
                :key="
                  hourStationIndex +
                    '-' +
                    manIndex +
                    '-' +
                    'hour_station_design_no'
                "
                class="pl-0 pt-8 text-center"
              >
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ hourStation.design_no }}
                </span>
              </td>

              <td
                :key="
                  hourStationIndex + '-' + manIndex + '-' + 'hour_station_hour'
                "
                class="pl-0 pt-8 text-center"
              >
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ hourStation.hour }}
                </span>
              </td>
            </template>
          </tr>

          <template
            v-for="(hourOnly, hourOnlyIndex) in dataMan.hour.length > 0
              ? dataMan.hour
              : []"
          >
            <tr
              :key="hourOnlyIndex + '-' + manIndex + 'man_index-next'"
              v-if="hourOnlyIndex > 0"
            >
              <td class="pl-0 pt-8 text-center"></td>

              <td class="pl-0 pt-8 text-center"></td>

              <td class="pl-0 pt-8 text-center"></td>

              <td class="pl-0 pt-8 text-center">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ hourOnly.assignment }}
                </span>
              </td>

              <td class="pl-0 pt-8 text-center">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ hourOnly.total_hour }}
                </span>
              </td>

              <template
                v-for="(hourStation, hourStationIndex) in hourOnly.hour_station"
              >
                <td
                  :key="
                    hourStationIndex +
                      '-' +
                      manIndex +
                      '-' +
                      'hour_station_design_no'
                  "
                  class="pl-0 pt-8 text-center"
                >
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ hourStation.design_no }}
                  </span>
                </td>

                <td
                  :key="
                    hourStationIndex +
                      '-' +
                      manIndex +
                      '-' +
                      'hour_station_hour'
                  "
                  class="pl-0 pt-8 text-center"
                >
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ hourStation.hour }}
                  </span>
                </td>
              </template>
            </tr>
          </template>
        </template>
      </template>
    </complete-table>
  </div>
</template>

<script>
import CompleteTable from "../../../content/widgets/advance-table/CompleteTable";
export default {
  name: "TableManHoursB",
  components: { CompleteTable },
  props: ["manHourAttachments"]
};
</script>
